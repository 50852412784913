require('slick-carousel')

export default class LibSlicksStepsSlider {
  constructor() {
    $(() => {
      const $slider = $('[data-ref="stepsGetLoanSlider"]')
      const $steps = $('#stepsGetLoanAccordeon [data-ref="stepsGetLoanAccordeonItem"]')

      $slider.slick({
        mobileFirst: true,
        arrows: false,
        dots: false,
        fade: true
      })

      const expandStep = (index) => {
        const $step = $steps.eq(index)
        const $trigger = $step.find('[data-target]')
        const $target = $step.find('[data-parent]')

        $trigger
          .removeClass('collapsed')
          .attr('aria-expanded', true)
        $target.slideDown(250)
      }
      const collapseStep = (index) => {
        const $step = $steps.eq(index)
        const $trigger = $step.find('[data-target]')
        const $target = $step.find('[data-parent]')

        $trigger
          .addClass('collapsed')
          .attr('aria-expanded', false)
        $target.slideUp(250)
      }

      // $slider.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
      //   $steps.map(index => (index !== nextSlide) && collapseStep(index))
      //
      //   expandStep(nextSlide)
      // })

      $steps.each((index, item) => {
        const $this = $(item)
        // const $trigger = $this.data('target')

        $this.on('click', (e) => {
          e.stopPropagation()
          e.preventDefault()

          $slider.slick('goTo', index)
          $steps.removeClass('active')
          $this.addClass('active')
        })
      })
    })
  }
}
