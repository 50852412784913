const range = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)

const intersect = (a, b) => {
  let t
  if (b.length > a.length) t = b
  b = a
  a = t
  // indexOf to loop over shorter
  return a.filter(e => b.indexOf(e) !== -1)
}

const merge = (...xs) => {
  if (xs?.length > 0) {
    return tap({}, m => xs.map(x => (() => {
      const result = []
      for (const k in x) {
        const v = x[k]
        result.push(m[k] = v)
      }
      return result
    })()))
  } else {
    return xs
  }
}

const tap = (o, fn) => {
  fn(o)
  return o
}

const matches = (el, selector) => {
  return (el.matches || el.matchesSelector || el.msMatchesSelector ||
    el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector)
}

const ready = fn => {
  csrfInit()

  if (document.readyState !== 'loading') {
    if (typeof fn !== 'function') return fn

    if (document.readyState === 'complete' && checkReload()) return fn({ skipListeners: true })

    document.addEventListener('turbolinks:load', fn)
  } else { document.addEventListener('DOMContentLoaded', fn) }
}

const csrfInit = () => {
  const token = $('meta[name="csrf-token"]').attr('content')
  if (token && token.length > 0) $.ajaxSetup({ headers: { 'X-CSRF-Token': token }})
}

const checkReload = () => {
  if (document.currentScript.dataset?.turbolinksTrack === 'keep') return false

  return document.currentScript.dataset?.turbolinksTrack === 'reload' ||
    document.currentScript.src.includes('components')
}

const preventDoubleClick = () => {
  const lastClickTimeStamp = $(event.currentTarget).attr('_ajax_send_time_stamp') || 0
  if (event.timeStamp - lastClickTimeStamp < 3000) {
    console.log('wait')
    return true
  } else {
    $(event.target).attr('_ajax_send_time_stamp', event.timeStamp)
    return false
  }
}

const cache = {}
const importAll = r => r.keys().forEach(key => (cache[key] = r(key)))

const addMultipleEventListener = (element, events, handler) => {
  events.forEach(e => element.addEventListener(e, handler))
}

const activateFormSubmit = (form, _place) => {
  const valid = form.checkValidity() && !form.querySelector('.form_float__group-error')
  console.log('FORM is', `${valid ? '' : 'IN'}VALID`)
  const buttons = [form.querySelector('button[type="submit"]'), form.querySelector('input[type="submit"]')]
  buttons.filter(button => button).forEach(button => {
    if (button.dataset.validity !== 'false') button.disabled = !valid
  })
}


export { range, matches, intersect, merge, ready, preventDoubleClick, importAll, addMultipleEventListener,
  activateFormSubmit }
